import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]
const nameApp = 'cni';
//const nameApp = 'dashboards';
export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'cni.users',
        path: `${APP_PREFIX_PATH}/${nameApp}/users`,
        component: React.lazy(() => import('views/app-views/cni/users')),
    },
    {
        key: 'cni.patients',
        path: `${APP_PREFIX_PATH}/${nameApp}/patients`,
        component: React.lazy(() => import('views/app-views/cni/patients')),
    },
    {
        key: 'cni.medicalHistory',
        path: `${APP_PREFIX_PATH}/${nameApp}/medicalHistory`,
        component: React.lazy(() => import('views/app-views/cni/medicalHistory')),
    },
    {
        key: 'cni.assessmentHistory',
        path: `${APP_PREFIX_PATH}/${nameApp}/assessmentHistory`,
        component: React.lazy(() => import('views/app-views/cni/assessmentHistory')),
    }
]