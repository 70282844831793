import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import users from '../../constants/DATA_TEST/users'
import patients from '../../constants/DATA_TEST/patients'
import medicalHistory from '../../constants/DATA_TEST/medicalHistory'
import assessmentHistory from '../../constants/DATA_TEST/assessmentHistory'
export const initialState = {
	loading: false,
	dataByReport:{}
}
const NAME_REPORT = {
	USERS:'users',
	PATIENTS:'patients',
	MEDICAL_HISTORY:'medicalHistory',
	ASSESSMENT_HISTORY:'assessmentHistory',
	
}
const getData = (nameReport) => {
	switch(nameReport){
		case NAME_REPORT.USERS: return users;
		case NAME_REPORT.PATIENTS: return patients;
		case NAME_REPORT.MEDICAL_HISTORY: return medicalHistory;
		case NAME_REPORT.ASSESSMENT_HISTORY: return assessmentHistory;
		default: return [];
	}
}
//export const loadData = createAsyncThunk('reports/',async (data, { rejectWithValue }) => {
  export const loadData = createAsyncThunk('reports',async ({nameReport}, { rejectWithValue }) => {
	/*const { email, password } = data
	try {
		const response = await FirebaseService.signInEmailRequest(email, password)
		if (response.user) {
			const token = response.user.refreshToken;
			localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);

			return token;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}*/
  //console.log({nameReport})
  //return rejectWithValue( 'Error')
  return {
    data:getData(nameReport),
    nameReport:nameReport,
    message:'',
    loading:false
  }
})
/*
export const signUp = createAsyncThunk('auth/register',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await FirebaseService.signUpEmailRequest(email, password)
		if (response.user) {
			const token = response.user.refreshToken;
			localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
			return token;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout',async () => {
    const response = await FirebaseService.signOutRequest()
		localStorage.removeItem(USER_LOGIN);
		localStorage.removeItem(AUTH_TOKEN);
    return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
	const response = await FirebaseService.signInGoogleRequest()
	if (response.user) {
		const token = response.user.refreshToken;
		console.log({user:response.user});
		localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
		const {displayName,email,photoURL} = response.user || {};
		const userLogin ={displayName,email,photoURL};
		localStorage.setItem(USER_LOGIN,JSON.stringify(userLogin));
		return {token,userLogin};
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
	const response = await FirebaseService.signInFacebookRequest()
	if (response.user) {
		const token = response.user.refreshToken;
		localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
		return token;
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
})
*/

export const tableGenericSlice = createSlice({
	name: 'tableGeneric',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
      const {nameReport,data} = action.payload
			state.dataByReport[nameReport] = data;
		},
		showAuthMessage: (state, action) => {
      const {nameReport,data} = action.payload
			state.dataByReport[nameReport] = data;
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.userLogin = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadData.pending, (state) => {
				state.loading = true
			})
			.addCase(loadData.fulfilled, (state, action) => {
        const {nameReport,data,loading} = action.payload
				state.loading = loading
				//state.redirect = '/'
        state.dataByReport[nameReport] = data;
			})
			.addCase(loadData.rejected, (state, action) => {
				state.loading = false
        const {nameReport,data} = action.payload
        state.dataByReport[nameReport] = data;
			})
			/*.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.userLogin = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.userLogin = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.userLogin = action.payload.userLogin
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})*/
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = tableGenericSlice.actions

export default tableGenericSlice.reducer